<template lang="pug">
  div
    template(v-if="!loading")
      v-card.mb-2(
        v-for="(item, index) in rations"
        :key="index")
        .list-1
          .list-1__title {{ item.name }}
          .list-1__item
            .list-1__item-label {{ 'base.note' | translate }}
            .list-1__item-value {{ item.comment }}

      v-table-body(
        v-if="!loading"
        outsideTable
        :empty="!rations.length")

    template(v-else)
      v-card.mb-2(v-for="i in 1" :key="i")
        .list-1
          .list-1__title
            .list-1__title-skeleton
          .list-1__item(v-for="j in 1" :key="`${i}-${j}`")
            .list-1__item-skeleton

</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'RationsTableMobile',

  data: () => ({
    loading: false
  }),

  computed: {
    ...mapGetters([
      'rations'
    ])
  },

  mounted () {
    this.loadData()
  },

  methods: {
    ...mapActions([
      'fetchRations'
    ]),

    async loadData () {
      this.loading = true
      await this.fetchRations()
      this.loading = false
    }
  }
}
</script>

<style lang="scss">
</style>
